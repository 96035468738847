import React, { useContext } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { GlobalContext } from "../context/provider.js"
import { navigate } from "gatsby"
import BrowserMessageModal from "../components/browserMessageModal"
import "./home.css"

const Home = () => {
  const { dispatch } = useContext(GlobalContext)

  return (
    <Container className="home-container">
      <Row className="home-row">
        <Col className="home-tagline">Compare 100s of home loans 'n' Save</Col>
      </Row>
      <Row className="home-row">
        <Col className="home-slogan">
          <p>Compare 100s of loans in seconds, hassle free.</p>
          <p>When you’re ready to apply, we’ll manage the process for you.</p>
        </Col>
      </Row>
      <Row className="home-row">
        <Col>
          <Button
            variant="primary"
            className="home-button"
            onClick={() => {
              dispatch({ type: "SET_REFINANCE", refinance: true })
              navigate("/product")
            }}
          >
            I Want To Refinance
          </Button>
        </Col>
      </Row>
      <Row className="home-row">
        <Col>
          <Button
            variant="secondary"
            className="home-button"
            onClick={() => {
              dispatch({ type: "SET_REFINANCE", refinance: false })
              navigate("/product")
            }}
          >
            I Need A Loan
          </Button>
        </Col>
      </Row>
      <BrowserMessageModal />
    </Container>
  )
}

export default Home
